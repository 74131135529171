import { handleAskCommand } from './commands-handler/ask';
import {
  AppCommand,
  AppCommandWithArgs,
  Inputs,
  MethodArgs,
} from './commands.models';

const separator =
  '---------------------------------------------------------------';

export class TerminalLogic {
  private appCommands: (AppCommandWithArgs | AppCommand)[] = [
    {
      name: 'me',
      args: [
        {
          name: 'contact',
          desc: ['Shows a way to contact me.'],
          method: () => ['Feel free to email me at leomaiajr@hotmail.com.'],
        },
        {
          name: 'github',
          desc: ['Goes to my GitHub profile page.'],
          method: () => {
            window.open('https://github.com/LeomaiaJr');
            return ['My Github username: LeomaiaJr'];
          },
        },
        {
          name: 'about',
          desc: ['Shows personal information about me.'],
          method: () => [
            'Hey! Please allow me to quick introduce myself.',
            "My name is Leonardo Maia Júnior and I'm from Brazil.",
            "I'm a developer with a passion for learning new things.",
            "I'm currently working as a Software Developer",
            'My favorite technology is Flutter.',
          ],
        },
      ],
      description:
        "The command 'me' is used to show general information about me (Leo).",
    },
    {
      name: 'xp',
      args: [
        {
          name: 'tech',
          desc: ['Shows my experience in some techs.'],
          method: (args) => {
            const newArgs = args as any as { tech: string | boolean };
            const techs: { [key: string]: string[] } = {
              angular: ['My level of experience with Angular is 9/10'],
              react: ['My level of experience with React is 7/10'],
              flutter: ['My level of experience with Flutter is 8/10'],
              nestjs: ['My level of experience with NestJS is 7/10'],
              postgres: ['My level of experience with PostgreSQL is 6/10'],
            };

            const unknown = (tech: string) => {
              const data = [`unknown option: tech=${tech}`, 'usage:'];

              const capitalizeFirstLetter = (str: string) =>
                str.charAt(0).toUpperCase() + str.slice(1);

              Object.keys(techs).forEach((key) => {
                data.push(
                  `   --tech=${key} (My experience with ${capitalizeFirstLetter(
                    key
                  )})`
                );
              });

              return data;
            };

            const arg = newArgs.tech.toString().toLowerCase();

            if (typeof newArgs.tech === 'string') {
              if (arg in techs) {
                return techs[newArgs.tech];
              } else {
                return unknown(newArgs.tech);
              }
            } else {
              let d: string[] = [];
              Object.keys(techs).map((tech) => d.push(...techs[tech]));
              return d;
            }
          },
        },
        {
          name: 'work',
          desc: ['Shows my work experience.'],
          method: (args) => {
            const workExpirences: {
              companyName: string;
              jobTitle: string;
              date: string;
              techsUsed: string;
            }[] = [
              {
                companyName: 'PD Soluções',
                jobTitle: 'Software Developer',
                date: 'March 2021 - Present',
                techsUsed:
                  'Angular, React, jQuery, NestJS, AdonisJS, PostgreSQL',
              },
            ];

            const data: string[] = [];

            let counter = 0;
            for (const workXp of workExpirences) {
              data.push(`${workXp.companyName} | ${workXp.date}`);
              data.push(`Role: ${workXp.jobTitle}`);
              data.push(`Techs: ${workXp.techsUsed}`);

              if (counter < workExpirences.length - 1) {
                data.push('');
                data.push(separator);
              }
            }

            return data;
          },
        },
      ],
      description:
        "The command 'xp' shows my experience in some technologies, works and projects.",
    },
    {
      name: 'ask',
      description: 'The command "ask" is used to ask me a question.',
      args: [
        {
          name: 'email',
          desc: ['Email to receive the answer.'],
          method: (args) => handleAskCommand(args),
        },
      ],
    },
    {
      name: 'help',
      description: "The command 'help' shows all commands.",
      method: () => descriptionHandler(this.appCommands),
    },
  ];

  generateCommands = () => {
    let commands: any = {};
    this.appCommands.forEach((command) => {
      commands[command.name] = {
        method: (args: MethodArgs, print: (output: string) => void) => {
          this.commandHandler({ args, print }, command);
        },
        options:
          'args' in command
            ? command.args.map((c) => {
                return { name: c.name };
              })
            : [],
      };
    });

    return commands;
  };

  commandHandler = (
    { args, print }: Inputs,
    command: AppCommandWithArgs | AppCommand
  ) => {
    if ('args' in command) {
      let foundArg = false;
      Object.keys(args).forEach((arg) => {
        command.args.forEach((a) => {
          if (a.name === arg) {
            foundArg = true;
            a.method(args).forEach((output) => {
              print(output);
            });
          }
        });
      });
      if (!foundArg) {
        print(`unknown option`);
        print('Usage:');
        command.args.forEach(({ name, desc }) => {
          print(`   --${name} (${desc})`);
        });
      }
    } else {
      command.method().forEach((output) => {
        print(output);
      });
    }
  };
}

export const descriptionHandler = (
  commands: (AppCommandWithArgs | AppCommand)[]
) => {
  const prints: string[] = [];

  prints.push('');
  prints.push('OK. Now, let me show some commands:');

  commands.forEach((command) => {
    prints.push('');
    prints.push(separator);
    prints.push('');
    prints.push(`# Command: ${command.name}`);
    prints.push(command.description);
    if ('args' in command) {
      prints.push('');
      prints.push('# Usage:');
      command.args.forEach(({ name, desc }) => {
        prints.push(`   --${name} (${desc})`);
      });
    }
  });
  prints.push('');
  prints.push(separator);
  prints.push('');
  prints.push('# Command: clear');
  prints.push("The command 'clear' is used to clear the terminal.");
  prints.push('');
  prints.push(separator);

  return prints;
};
