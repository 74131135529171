import { MethodArgs } from '../commands.models';
import emailjs from '@emailjs/browser';

export const handleAskCommand = (args: MethodArgs | undefined): string[] => {
  const newArgs = args as any as {
    email: string | boolean;
  };

  const usage = 'usage: ask --email=me@example.com';

  if (typeof newArgs.email !== 'string') {
    return ['You must provide an email to receive the answer.', usage];
  } else {
    if (newArgs.email.match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/)) {
      const email = newArgs.email.replace(/['"]+/g, '');

      const question = prompt('Type your question here: ');

      if (question !== null) {
        emailjs
          .send(
            'service_zoujv31',
            'template_6dvjn8b',
            {
              question,
              receiver: email,
            },
            'a4cE8hoOqgtSVP_T0'
          )
          .then(
            function (response) {},
            function (error) {
              console.log('FAILED... to send email');
            }
          );
        return [
          'Your question has been sent to Leo',
          'You will receive the anwser on the email you provided :)',
        ];
      } else return ['You must provide a question.'];
    } else return ['You must provide a valid email to receive the answer.'];
  }
};
