import Terminal from "terminal-in-react";
import { TerminalLogic } from "./commands/commands";

import "./style.css";

const AppTerminal: React.FC = () => {
  const commands = new TerminalLogic().generateCommands();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="actions">
        <div className="circle red"></div>
        <div className="circle orange"></div>
        <div className="circle green"></div>
      </div>
      <div id="border" />
      <span className="default-message">
        ## Hi I'm Leo Maia Júnior <br />
        ## This is a fun way to present myself!
        <br />
        ## <br />
        ## Type 'help' and hit return to learn how to explore.
      </span>
      <Terminal
        hideTopBar={true}
        allowTabs={false}
        barColor="#282936"
        prompt="#5AB6C1"
        startState="maximised"
        promptSymbol="leomaiajr >"
        color="#67FED7"
        outputColor="white"
        backgroundColor="#282936"
        style={{ fontWeight: "bold", fontSize: "2.5vh" }}
        commandPassThrough={(cmd, print: (text: string) => void) => {
          print(`command '${cmd}' not found`);
        }}
        commands={{
          ...commands,
        }}
      />
    </div>
  );
};

export default AppTerminal;
